import { EventConfirmationProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<EventConfirmationProps> = {
	schemaType: "template",
	displayName: "Event Confirmation",
	component: "EventConfirmation",
	dataPacks: ["EVENTS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			title: "Note",
			key: "note",
			type: "NoteField",
			value: {
				title: "Event Confirmation Template",
				text: "This is the generic template for event confirmations. The data of the event will be displayed here and the user will be able to confirm their attendance.",
			},
		},
	],

	default: {
		type: "template",
		templateType: "EventConfirmation",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventConfirmation/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventConfirmation/thumbnail@2x.png",
	},
};

export default schema;
