const es_ES = {
	global: {
		skipToContent: "Saltar al contenido",
		readMore: "Leer más",
		knowMore: "Saber más",
		download: "Descargar",
		new: "Nuevo",
		search: "Buscar",
		filter: "Filtrar",
		playVideo: "Reproducir el vídeo",
		close: "Cerrar",
		siteWeb: "Sitio web",
		cookie_settings: "Configuración de cookies",
		removeFilters: "Borrar filtros",
	},
	languages: {
		short: {
			en: "ENG",
			es: "ESP",
		},
		long: {
			en: "INGLÉS",
			es: "ESPAÑOL",
		},
	},
	form: {
		dont_results: "No hay opciones disponibles",
		select_i_am: {
			student: "Estudiante",
			legal_tutor: "Padre / Madre / Tutor legal",
			teacher: "Profesor / Orientador",
			other: "Otro (especificar)",
		},
		genre: {
			female: "Femenino",
			male: "Masculino",
			other: "Prefiero no decirlo",
		},
		beginning_date_options: {
			"2025-26": "Curso académico 2025-2026",
			other: "Otro",
		},
		i_am: "Soy...",
		placeholder_i_am: "Selecciona una opción",
		i_am_other: 'Especificar "Otro"',
		placeholder_i_am_other: 'Especificar "Otro"',
		name: "Nombre",
		placeholder_name: "Nombre",
		lastname: "Apellidos",
		placeholder_lastname: "Apellidos",
		gender: "Género",
		placeholder_gender: "Selecciona una opción",
		email: "Email",
		placeholder_email: "Email",
		country: "País",
		placeholder_country: "Selecciona una opción",
		region: "Provincia",
		placeholder_region: "Provincia",
		phone: "Télefono",
		placeholder_phone: "Télefono",
		number_phone: "Número de teléfono",
		placeholder_number_phone: "Número de teléfono",
		when_start: "¿Cuándo te gustaría iniciar tus estudios?",
		placeholder_when_start: "Selecciona una opción",
		privacy_policy: `He leído y acepto la información sobre <a href="/politica-de-privacidad/" target="_blank" rel="noopener">el tratamiento de datos personales</a>.`,
		data_protection: "Información básica sobre protección de datos:",
		data_protection_text: `**Responsable:** COLEGIO UNIVERSITARIO DE ESTUDIOS FINANCIEROS.\t
**Finalidad:** Resolver las consultas y dudas formuladas por los usuarios.\t
**Derechos:** En las condiciones legales, tiene derecho a acceder, rectificar y suprimir los datos, a la limitación de su tratamiento, a oponerse al mismo y a su portabilidad.\t
**Información adicional:**  Puede consultar la información adicional y detallada sobre Protección de Datos en el siguiente link.
	 	`,
		send_academic_information: `Acepto recibir comunicaciones comerciales de CUNEF Universidad. <a href="/politica-de-privacidad/" target="_blank" rel="noopener">Ver información adicional</a>.`,
		contact_by_whatsapp:
			"Autorizo que CUNEF se ponga en contacto conmigo y me remita información a través de WhatsApp",
		study_type: "Tipo de estudio",
		placeholder_study_type: "Selecciona una opción",
		study_type_options: {
			degree: "Grado",
			postgraduate: "Postgrado",
			minors: "Minor",
			lifelongLearning: "Formación permanente",
		},
		study_area: "Áreas de interés",
		placeholder_study_area: "Selecciona una opción",
		study_area_options: {
			company_economy_finance: "Empresa / Economía / Finanzas",
			data_ia: "Data / AI",
			engineering: "Ingeniería",
			international_institutions_law: "Derecho / Instituciones internacionales",
		},
		errors: {
			required: "Campo obligatorio",
		},
		process_form: "Enviando formulario.",
		title_submitted: "Gracias por tu interés",
		content_submitted:
			"Nos pondremos en contacto contigo a la mayor brevedad posible.",
		reset_form: "Cerrar y volver",
		selectACategory: "Selecciona una categoría",
		filterBy: "Filtrar por",
		select_program: "¿Que te gustaría estudiar?",
		placeholder_select_program: "Selecciona una opción",
		type_identity_card: "¿Tipo de documento?",
		placeholder_type_identity_card: "¿Tipo de documento?",
		options_type_identity_card: {
			DNI: "DNI",
			Pasaporte: "Pasaporte",
			NIE: "NIE",
			Otro: "Otro",
		},
		identity_card: "Número de documento",
		placeholder_identity_card: "Número de documento",
		date_birth: "Fecha de nacimiento",
		placeholder_date_birth: "Fecha de nacimiento",
		accompanying_persons_tickets: "Entradas para acompañantes",
		placeholder_accompanying_persons_tickets: "Entradas para acompañantes",
		grade_candidate_entries: "Entradas candidato de grado",
		placeholder_grade_candidate_entries: "Entradas candidato de grado",
		submit: "Enviar",
	},
	components: {
		pagination: {
			previous: "Anterior",
			next: "Siguiente",
		},
		ods: {
			svg: "ES",
			health_and_wellBeing: "Salud y bienestar",
			no_poverty: "Fin de la pobreza",
			zero_hunger: "Hambre cero",
			quality_education: "Educación de calidad",
			gender_equally: "Igualdad de género",
			clear_water_and_sanitation: "Agua limpia y saneamiento",
			affordable_and_clean_energy: "Energía asequible y no contaminante",
			decent_work_and_economic_growth:
				"Trabajo decente y crecimiento económico",
			industry_innovation_and_infrastructure:
				"Industria, innovación e infraestructura",
			reduced_inequalities: "Reducción de las desigualdades",
			sustainable_cities_and_communities: "Ciudades y comunidades sostenibles",
			responsible_consumption_and_production:
				"Consumo y producción responsable",
			climate_action: "Acción climática",
			life_below_water: "Vida submarina",
			life_on_land: "Vida de ecosistemas terrestres",
			peace_justice_and_strong_institutions:
				"Paz, justicia e instituciones sólidas",
			partnership_for_the_goals: "Alianzas para lograr los objetivos",
		},
	},
	modules: {
		socialShare: {
			share: "Compartir",
		},
		locationMap: {
			goToWebsite: "Ir a la web",
		},
		carousel: {
			previousSlide: "Ir a la slide anterior",
			nextSlide: "Ir a la siguiente slide",
		},
		landingButtonsProgram: {
			cta: "Solicitar Información",
		},
		eventDistributor: {
			form: {
				event_category: "Categoría de evento",
			},
		},
	},
	templates: {
		professorDetail: {
			office: "Despacho",
			phd: "PhD",
			departments: "Departamentos",
		},
		programDetail: {
			heading: "Presentación",
			programType: "Programa",
			learningOption: "Tipo de enseñanza",
			language: "Idioma",
			credits: "Créditos",
			startAdmission: "Iniciar admisión",
			downloadBrochure: "Descargar folleto",
			places: "Plazas",
		},
		eventDetail: {
			date: "Fecha",
			place: "Lugar",
			hour: "Hora",
			format: "Formato",
			addCalendar: "Añadir evento al calendario",
			highlightedEvents: "Eventos destacados",
		},
		eventConfirmation: {
			noEvent: "Evento no encontrado",
			formContent:
				"Te rogamos, por favor, nos indiques si seguimos contando con tu participación y la de tus acompañantes a este evento.",
			email: "Introduce el email con el que te inscribiste",
			parentsLabel: "Nº padres",
			parentsTitle: "Confirma número de padres",
			candidatesLabel: "Nº candidatos",
			candidatesTitle: "Confirma número de candidatos",
			attendance: "¿Asistirás al evento?",
			companionsAttendance: "¿Asistirán tus acompañantes?",
			yes: "Sí",
			no: "No",
		},
	},
};
export default es_ES;
