import { EventDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<EventDetailProps> = {
	schemaType: "template",
	displayName: "Event Detail",
	component: "EventDetail",
	dataPacks: ["EVENTS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	content: [
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			mandatory: true,
		},

		{
			type: "DateField",
			key: "eventDate",
			title: "Date",
			mandatory: true,
		},

		{
			type: "TextField",
			title: "Hour",
			key: "hour",
			mandatory: true,
		},

		{
			type: "TextField",
			title: "Place",
			key: "place",
			mandatory: true,
		},

		{
			type: "TextField",
			title: "Event Mode",
			key: "eventMode",
			hideable: true,
			placeholder: "Type format",
		},

		{
			type: "RichText",
			title: "Content",
			key: "content",
			mandatory: true,
			placeholder: "Type a text",
		},

		{
			title: "Event Category",
			type: "AsyncCheckGroup",
			key: "eventCategory",
			source: "EVENTS_CATEGORY",
		},

		{
			type: "FieldGroup",
			key: "downloadButton",
			title: "Download Button",
			fields: [
				{
					type: "TextField",
					title: "Download Label",
					key: "downloadLabel",
					hideable: true,
				},
				{
					title: "Download File",
					type: "FileField",
					key: "downloadFile",
				},
			],
		},
		{
			type: "ComponentContainer",
			title: "Form",
			key: "form",
			whiteList: ["EventForm"],
			hideable: true,
		},
		{
			type: "ComponentContainer",
			title: "More Info Button",
			key: "moreInfoButton",
			whiteList: ["Button"],
			hideable: true,
		},
		{
			type: "VisualUniqueSelection",
			title: "Layout",
			key: "layout",
			columns: 2,
			mandatory: true,
			options: [
				{
					value: "layout001",
					img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout1.png",
				},
				{
					value: "layout002",
					img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout2.png",
				},
			],
		},
	],

	default: {
		type: "template",
		templateType: "EventDetail",
		image: undefined,
		eventDate: "",
		hour: "",
		place: "",
		eventMode: "",
		content: "Lorem ipsum",
		eventCategory: [],
		downloadLabel: undefined,
		downloadFile: undefined,
		form: {
			component: "EventForm",
			title: {
				content: "Lorem ipsum",
				tag: "h3",
			},
			content: "Lorem ipsum paragraph",
			buttonLabel: "Enviar",
			data: {
				mode: "auto",
				order: "alpha",
				quantity: 0,
				sources: [{ structuredData: "PROGRAM" }],
			},
		},
		moreInfoButton: {
			component: "Button",
			url: {
				newTab: true,
				noFollow: false,
				href: "",
			},
		},
		layout: "layout001",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventDetail/thumbnail@2x.png",
	},
};

export default schema;
