import * as React from "react";

const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const Error404 = React.lazy(() => import("./Error404"));
const LandingProgram = React.lazy(() => import("./LandingProgram"));
const LegalTemplate = React.lazy(() => import("./LegalTemplate"));
const NewsDetail = React.lazy(() => import("./NewsDetail"));
const NewsList = React.lazy(() => import("./NewsList"));
const ProfessorDetail = React.lazy(() => import("./ProfessorDetail"));
const ProfessorList = React.lazy(() => import("./ProfessorList"));
const ProgramDetail = React.lazy(() => import("./ProgramDetail"));
const ProgramList = React.lazy(() => import("./ProgramList"));
const EventDetail = React.lazy(() => import("./EventDetail"));
const EventList = React.lazy(() => import("./EventList"));
const EventConfirmation = React.lazy(() => import("./EventConfirmation"));

export default {
	BasicTemplate,
	Error404,
	LandingProgram,
	LegalTemplate,
	NewsDetail,
	NewsList,
	ProfessorDetail,
	ProfessorList,
	ProgramDetail,
	ProgramList,
	EventDetail,
	EventList,
	EventConfirmation,
};
