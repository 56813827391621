import { Schema } from "@griddo/core";

export const EVENTS: Schema.PageContentType = {
	dataPacks: ["EVENTS_PACK"],
	title: "Events",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["EventDetail"],
		fields: [
			// Title is mandatory and must be in the first place of `fields`
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},

			{
				type: "ImageField",
				title: "Image",
				key: "image",
				from: "image",
			},

			{
				type: "DateField",
				key: "eventDate",
				title: "Date",
				from: "eventDate",
				indexable: true,
			},

			{
				type: "TextField",
				title: "Hour",
				from: "hour",
				key: "hour",
			},

			{
				type: "TextField",
				title: "Place",
				from: "place",
				key: "place",
			},

			{
				type: "TextField",
				title: "Event Mode",
				from: "eventMode",
				key: "eventMode",
			},

			{
				type: "RichText",
				title: "Content",
				key: "content",
				from: "content",
			},

			{
				title: "Event Category",
				type: "AsyncCheckGroup",
				key: "eventCategory",
				from: "eventCategory",
				source: "EVENTS_CATEGORY",
			},

			{
				type: "TextField",
				title: "Download Label",
				from: "downloadLabel",
				key: "downloadLabel",
			},

			{
				title: "Download File",
				type: "FileField",
				key: "downloadFile",
				from: "downloadFile",
			},
			{
				type: "ComponentContainer",
				title: "Form",
				key: "form",
				whiteList: ["EventForm"],
				from: "form",
			},
			{
				type: "ComponentContainer",
				title: "More Info Button",
				key: "moreInfoButton",
				from: "moreInfoButton",
				whiteList: ["Button"],
			},
		],
	},
};
